import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import ClipLoader from 'react-spinners/ClipLoader';

import { saveRepair } from '../../actions/repairsActions';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './EditRepairForm.module.css';

export default function EditRepairForm({ appointmentId, entryId, surveyId, fieldId, resetSection, onSave, noSave }) {
    const dispatch = useDispatch();

    const repairsSelector = useSelector(state => state.repairs);
    const { saveLoading } = repairsSelector;

    const [notes, setNotes] = useState('');
    const [completion, setCompletion] = useState('');

    const handleSave = () => {
        dispatch(saveRepair(appointmentId, surveyId, entryId, fieldId, notes, completion, resetSection, noSave));   
        onSave(); 
    }

    useEffect(() => {
        setNotes('');
        setCompletion(new Date());
    }, [fieldId]);

    return (
        <div className={styles.formContainer}>
            <textarea className={styles.formTextarea} rows={6} placeholder="Completion notes go here" value={notes} onChange={(e) => setNotes(e.target.value)} />
            <div className={styles.completedContainer}>
                <label className={styles.completedDateLabel}>Date Completed</label>
                <DatePicker className={styles.completedDate} dateFormat="dd-MM-yyyy" autoComplete="off" selected={completion} onChange={(date) => setCompletion(date)} />
                <button className={styles.saveButton} onClick={handleSave} disabled={saveLoading}>Save <ClipLoader loading={saveLoading} color={'#ffffff'} css={'margin-left: 8px'} size={15} /></button>
            </div>
        </div>
    );
}

EditRepairForm.defaultProps = {
    onSave: () => {},
    noSave: false,
}