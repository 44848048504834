import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PulseLoader from 'react-spinners/PulseLoader';
import _ from 'lodash';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';

import { createSurvey, updateSurvey } from '../../actions/surveyActions';

import { CLEAR_DOORLIST_PROCEED, CLEAR_SURVEY } from '../../types/surveyTypes';

export const InspectionForm = ({ showEdit }) => {
    const [survey, setSurvey] = useState(null);
    const [clientName, setClientName] = useState('');
    const [clientTelephone, setClientTelephone] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [surveyAddress, setSurveyAddress] = useState('');
    const [comments, setComments] = useState('');
    const [errors, setErrors] = useState(null);

    const surveySelector = useSelector((state) => state.survey);
    const { surveyLoading, doorListProceed } = surveySelector;

    const authSelector = useSelector((state) => state.auth);
    const { user } = authSelector;

    const costsSelector = useSelector((state) => state.costs);
    const { costs, costsCount } = costsSelector;

    const dispatch = useDispatch();
    const history = useHistory();
    
    useEffect(() => {
        if (showEdit) {
            const surveyId = localStorage.getItem('activeSurvey');
            const clientData = JSON.parse(localStorage.getItem('clientData'));
    
            if (surveyId) {
                const existingSurvey = JSON.parse(localStorage.getItem('survey'));
                
                if (existingSurvey) {
                    if (existingSurvey[surveyId]) {
                        setSurvey(existingSurvey[surveyId]);
                    }
                }
                
                if (clientData[surveyId]) {
                    setClientName(clientData[surveyId]?.inspection_client_name);
                    setClientTelephone(clientData[surveyId]?.inspection_client_telephone);
                    setClientEmail(clientData[surveyId]?.inspection_client_email);
                    setSurveyAddress(clientData[surveyId]?.inspection_survey_address);
                    setComments(clientData[surveyId]?.inspection_comments);
                }
            }
        } else {
            dispatch({ type: CLEAR_SURVEY });
        }
        
    }, []);

    const onSubmit = event => {
        event.preventDefault();
        setErrors(null);

        let errs = {};
        const { recordId } = survey || 0;

        if (!clientName) {
            const err = { inspection_client_name: { message: 'Please provide a client name' }};

            Object.assign(errs, err);
        }

        if (!clientTelephone) {
            const err = { inspection_client_telephone: { message: 'Please provide a client telephone number' }};

            Object.assign(errs, err);
        }

        if (!clientEmail) {
            const err = { inspection_client_email: { message: 'Please provide a client email address' }};

            Object.assign(errs, err);
        }

        if (!surveyAddress) {
            const err = { inspection_survey_address: { message: 'Please provide a survey address' }};

            Object.assign(errs, err);
        }

        if (clientName && clientTelephone && clientEmail && surveyAddress) {

            if (showEdit) {
                // dispatch(updateSurvey(parseInt(user?.customer_id), clientName, clientAddress, clientTelephone, clientEmail, surveyAddress, surveyBuilding, contactName, contactTelephone, contactEmail, buildingPhoto, floorPlan, comments, includeCosts, recordId ));
                dispatch(updateSurvey(clientName, clientTelephone, clientEmail, surveyAddress, comments, recordId));
            } else {
                // dispatch(createSurvey(parseInt(user?.customer_id), clientName, clientAddress, clientTelephone, clientEmail, surveyAddress, surveyBuilding, contactName, contactTelephone, contactEmail, buildingPhoto, floorPlan, comments, includeCosts ));
                dispatch(createSurvey(parseInt(user?.customer_id), clientName, clientTelephone, clientEmail, surveyAddress, comments));
            }
        } else {
            setErrors(errs);
        }
    }

    useEffect(() => {
        if (doorListProceed) {
            history.push('/survey/doors');

            dispatch({ type: CLEAR_DOORLIST_PROCEED });
        }
    }, [doorListProceed]);

    useEffect(() => {
        if (!costs || _.values(costs).length !== parseInt(costsCount)) {
            history.push('/costs');
        }
    }, [costs])
    
    return (
        <>
            <Header>Door Survey - Client Details</Header>
            <Menu />
            <Content style={{ marginTop: '100.27px' }} >
                <span className="login__welcome">Please complete all fields</span>
                <form className="login__form" onSubmit={onSubmit}>
                    <div className="form-group">
                        <label className="form__label" htmlFor="clientName">Client Name</label>
                        <input className="form__input" type="text" id="clientName" name="clientName" value={clientName} autoComplete={false} onChange={(e) => setClientName(e.target.value)} />
                        <div className="error">{errors?.inspection_client_name?.message}</div>
                    </div>
                    <div className="form-group">
                        <label className="form__label" htmlFor="clientTelephone">Telephone Number</label>
                        <input className="form__input" type="text" id="clientTelephone" name="clientTelephone" value={clientTelephone} autoComplete={false} onChange={(e) => setClientTelephone(e.target.value)} />
                        <div className="error">{errors?.inspection_client_telephone?.message}</div>
                    </div>
                    <div className="form-group">
                        <label className="form__label" htmlFor="clientEmail">Email Address</label>
                        <input className="form__input" type="text" id="clientEmail" name="clientEmail" value={clientEmail} autoComplete={false} onChange={(e) => setClientEmail(e.target.value)} />
                        <div className="error">{errors?.inspection_client_email?.message}</div>
                    </div>
                    <div className="form-group">
                        <label className="form__label" htmlFor="surveyAddress">Survey Address</label>
                        <input className="form__input" type="text" id="surveyAddress" name="surveyAddress" value={surveyAddress} autoComplete={false} onChange={(e) => setSurveyAddress(e.target.value)} />
                        <div className="error">{errors?.inspection_survey_address?.message}</div>
                    </div>
                    <div className="form-group">
                        <label className="form__label" htmlFor="comments">Comments</label>
                        <input className="form__input" type="text" id="comments" name="comments" value={comments} autoComplete={false} onChange={(e) => setComments(e.target.value)} />
                        <div className="error">{errors?.inspection_comments?.message}</div>
                    </div>
                    <div className="form__buttons">
                        {showEdit ? (
                            <Fragment>
                                <button aria-label="Back" className="form__button secondary form__button--inline" type="button" onClick={() => history.push('/survey/doors')} style={{ marginRight: '1rem' }}>Back</button>
                                <button aria-label="Update & Continue" disabled={surveyLoading} className="form__button form__button--inline" type="submit">Update & Continue <PulseLoader loading={surveyLoading} color={'#ffffff'} css={'margin-left: 8px'} size={5} /></button>
                            </Fragment>
                        ) : (
                            <button aria-label="Save & Continue" disabled={surveyLoading} className="form__button form__button--inline" type="submit">Save & Continue <PulseLoader loading={surveyLoading} color={'#ffffff'} css={'margin-left: 8px'} size={5} /></button>
                        )}
                    </div>
                </form>
            </Content>
            <BottomNav />
        </> 
    )
}