import React from 'react';
import Skeleton from "react-loading-skeleton";

import 'react-loading-skeleton/dist/skeleton.css'

export default function LoadingSkeleton({ count, height }) {
    return <Skeleton style={{ marginTop: '1rem' }} count={count} height={height} />    
}

LoadingSkeleton.defaultProps = {
    count: 5,
    height: 40,
};