import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ReactComponent as LoginIcon } from '../../assets/login.svg';
import { ReactComponent as Survey } from '../../assets/survey.svg';
import { ReactComponent as Cash } from '../../assets/cash.svg';
import { ReactComponent as Home } from '../../assets/home.svg';

import './BottomNav.css';

const BottomNav = () => {
    const authSelector = useSelector((state) => state.auth);
    const { user } = authSelector;

    return (
        <div className="bottomNav">
            {user ? (
                <Fragment>
                    <NavLink to="/" exact className="bottomNav__link" activeClassName="active"><Home />Dashboard</NavLink>
                    <NavLink to="/survey/new" exact className="bottomNav__link" activeClassName="active"><Survey />Survey</NavLink>
                    <NavLink to="/costs" exact className="bottomNav__link" activeClassName="active"><Cash />Costs</NavLink>
                </Fragment>
            ) : (
                <Fragment>
                    <NavLink to="/login" className="bottomNav__link" activeClassName="active"><LoginIcon style={{ width: '33px', height: '33px' }} />Login</NavLink>
                </Fragment>
            )}
        </div>
    )
}

export default BottomNav;
