import axios from 'axios';
import Cookie from 'js-cookie';
import _ from 'lodash';

import { RESET_DOOR_ANSWER, SAVE_TO_LOCALSTORAGE_REQUEST } from '../types/doorTypes';
import { LATEST_SURVEY_FAILURE, LATEST_SURVEY_REQUEST, LATEST_SURVEY_SUCCESS, LOAD_ALL_REPAIRS_FAILURE, LOAD_ALL_REPAIRS_REQUEST, LOAD_ALL_REPAIRS_SUCCESS, LOAD_REPAIRS_FAILURE, LOAD_REPAIRS_REQUEST, LOAD_REPAIRS_SUCCESS, LOAD_REPAIR_HISTORY_FAILURE, LOAD_REPAIR_HISTORY_REQUEST, LOAD_REPAIR_HISTORY_SUCCESS, SAVE_DOOR_REPAIR_FAILURE, SAVE_DOOR_REPAIR_REQUEST, SAVE_DOOR_REPAIR_RESET, SAVE_DOOR_REPAIR_SUCCESS } from '../types/repairsTypes';

export const loadRepairs = (id) => async dispatch => {
    try {
        dispatch({ type: LOAD_REPAIRS_REQUEST });

        const { data } = await axios.get('https://app.webglu21.co.uk/app_handler.php', { headers: { 'WG-Method': 'LOAD_REPAIRS', 'Wg-SurveyId': id, 'WG-Key': Cookie.get('accessToken') }});

        dispatch({ type: LOAD_REPAIRS_SUCCESS, payload: data });

        console.log(data?.repairs)

        localStorage.setItem('repairs', JSON.stringify(data?.repairs));
        localStorage.setItem('doorsActionRequiredCount', data?.count);

    } catch (error) {
        dispatch({ type: LOAD_REPAIRS_FAILURE });
    }
}

export const loadAllRepairs = () => async dispatch => {
    try {
        dispatch({ type: LOAD_ALL_REPAIRS_REQUEST });

        const { data } = await axios.get('https://app.webglu21.co.uk/app_handler.php', { headers: { 'WG-Method': 'LOAD_ALL_REPAIRS', 'WG-Key': Cookie.get('accessToken') }});

        dispatch({ type: LOAD_ALL_REPAIRS_SUCCESS, payload: data });

        localStorage.setItem('allRepairs', JSON.stringify(data?.repairs));
        localStorage.setItem('totalDoorsWithActionRequired', data?.count);
    } catch (error) {
        dispatch({ type: LOAD_ALL_REPAIRS_FAILURE });
    }
}

export const loadRepairHistory = (id) => async dispatch => {
    try {
        dispatch({ type: LOAD_REPAIR_HISTORY_REQUEST });

        const { data } = await axios.get('https://app.webglu21.co.uk/app_handler.php', { headers: { 'WG-Method': 'LOAD_REPAIR_HISTORY', 'Wg-SurveyId': id, 'WG-Key': Cookie.get('accessToken') }});

        dispatch({ type: LOAD_REPAIR_HISTORY_SUCCESS, payload: data?.repairs });

    } catch (error) {
        dispatch({ type: LOAD_REPAIR_HISTORY_FAILURE });
    }
}

export const latestSurvey = (id) => async dispatch => {
    try {
        dispatch({ type: LATEST_SURVEY_REQUEST });

        const { data } = await axios.get('https://app.webglu21.co.uk/app_handler.php', { headers: { 'WG-Method': 'LATEST_SURVEY', 'Wg-SurveyId': id, 'WG-Key': Cookie.get('accessToken') }});

        dispatch({ type: LATEST_SURVEY_SUCCESS, payload: data });

        localStorage.setItem('latestSurvey', data);

    } catch (error) {
        const latestSurvey = localStorage.getItem('latestSurvey');

        dispatch({ type: LATEST_SURVEY_FAILURE, payload: latestSurvey });
    }
}

export const saveRepair = (appointmentId, surveyId, entryId, fieldId, notes, completion, resetSection, noSave) => async (dispatch, getState) => {
    const state = getState();
    let { app: { sections }, door: { doors }} = state;

    const tmpSections = _.cloneDeep(sections);

    try {
        const currentDoor = _.values(doors).find((door) => door.parentId.toString() === entryId);

        if (resetSection) {
            const section = tmpSections.find((s) => s.id.toString() === fieldId);
    
            const door = _.values(doors).find((door) => door.parentId.toString() === entryId);
            
            if (door) {
                const index = doors[door.id].answers.findIndex((s) => s.id.toString() === fieldId);
                
                doors[door.id].answers[index] = section;
            }
        }

        dispatch({ type: SAVE_DOOR_REPAIR_REQUEST });

        await axios.post('https://app.webglu21.co.uk/app_handler.php', { notes, completion }, { headers: { 'WG-Method': 'SAVE_REPAIR_EDIT', 'Wg-Surveyid': surveyId, 'Wg-EntryId': entryId, 'Wg-Appointmentid': appointmentId, 'WG-Key': Cookie.get('accessToken') }});

        if (noSave) {
            dispatch({ type: SAVE_DOOR_REPAIR_RESET });
        } else {
            dispatch({ type: SAVE_DOOR_REPAIR_SUCCESS, payload: { surveyId: entryId, appointmentId } });
        }
        
        if (currentDoor && resetSection) {
            dispatch({ type: RESET_DOOR_ANSWER, payload: doors });
            dispatch({ type: SAVE_TO_LOCALSTORAGE_REQUEST });
        }

    } catch (error) {
        dispatch({ type: SAVE_DOOR_REPAIR_FAILURE, payload: { surveyId: entryId, appointmentId } });
    }
}