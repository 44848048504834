import axios from 'axios';
import Cookie from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

import { LOAD_SURVEY_REQUEST, LOAD_SURVEY_SUCCESS, LOAD_SURVEY_FAILURE, CREATE_SURVEY_REQUEST, CREATE_SURVEY_SUCCESS, CREATE_SURVEY_FAILURE, LOAD_DOOR_REQUEST, LOAD_DOOR_SUCCESS, LOAD_DOOR_FAILURE,UPDATE_SURVEY_REQUEST, UPDATE_SURVEY_SUCCESS, CLEAR_DOOR_PROCEED, SAVE_DOOR_REF_REQUEST, SAVE_DOOR_REF_SUCCESS, SAVE_DOOR_REF_FAILURE, REMOVE_ALL_DOORS_REQUEST, REMOVE_ALL_DOORS_SUCCESS, REMOVE_ALL_DOORS_FAILURE, SET_PDF_SELECTOR_TRUE, CREATE_SURVEY_ENTRY_REQUEST, CREATE_SURVEY_ENTRY_SUCCESS, POPULATE_SURVEY_DOORS, CREATE_SURVEY_ENTRY_FAILURE } from '../types/surveyTypes';
import { CLEAR_DOORS, LOAD_DOORS, POPULATE_DOORS_FAILURE, POPULATE_DOORS_REQUEST, POPULATE_DOORS_SUCCESS } from '../types/doorTypes';

export const loadSurvey = (surveyKey) => dispatch => {
    try {
        if (!surveyKey) {
            throw Error('No survey key');
        }

        dispatch({ type: LOAD_SURVEY_REQUEST });
        
        const savedSurveys = JSON.parse(localStorage.getItem('survey'));

        const survey = savedSurveys && _.values(savedSurveys).find((s) => s.surveyKey === surveyKey);

        if (survey) {

            const doors = JSON.parse(localStorage.getItem('doors'));

            dispatch({ type: LOAD_SURVEY_SUCCESS, payload: { ...savedSurveys[survey?.id] }});

            if (doors) {
                const surveyDoors = doors[survey?.id];

                dispatch({ type: LOAD_DOORS, payload: surveyDoors });
            }

            localStorage.setItem('activeSurvey', survey?.id);
        } else {
            throw Error('Survey not found');
        }
    } catch (error) {
        dispatch({ type: LOAD_SURVEY_FAILURE });
    }
}

export const loadSurveyWithID = (surveyId) => dispatch => {
    try {
        if (!surveyId) {
            throw Error('No survey key');
        }

        dispatch({ type: LOAD_SURVEY_REQUEST });
        
        const savedSurveys = JSON.parse(localStorage.getItem('survey'));

        const survey = savedSurveys[surveyId];

        if (survey) {

            const doors = JSON.parse(localStorage.getItem('doors'));

            dispatch({ type: LOAD_SURVEY_SUCCESS, payload: { ...savedSurveys[surveyId] }});

            if (doors) {
                const surveyDoors = doors[survey?.id];

                dispatch({ type: LOAD_DOORS, payload: surveyDoors });
            }

            localStorage.setItem('activeSurvey', survey?.id);
        } else {
            throw Error('Survey not found');
        }
    } catch (error) {
        dispatch({ type: LOAD_SURVEY_FAILURE });
    }
}

export const createSurvey = (userId, hotel, name, type, frequency) => async (dispatch, getState) => {
    const formPayload = {
        hotel_name: hotel,
        survey_name: name,
        survey_type: type,
        survey_frequency: frequency,
    };
    
    const fd = new FormData();

    fd.append('hotel_name', hotel);
    fd.append('survey_name', name);
    fd.append('survey_type', type);
    fd.append('survey_frequency', frequency);

    const surveyId = uuidv4();

    let survey = JSON.parse(localStorage.getItem('survey'));

    dispatch({ type: CLEAR_DOORS });

    try {
        dispatch({ type: CREATE_SURVEY_REQUEST });

        const { data } = await axios.post('https://app.webglu21.co.uk/app_handler.php', fd, { headers: { 'WG-Method': 'SAVE_SURVEY_FORM', 'WG-Key': Cookie.get('accessToken') }});

        console.log(data);

        dispatch({ type: CREATE_SURVEY_SUCCESS, payload: { id: surveyId, userId, recordId: data?.record_id, doors: {}, surveyKey: data?.survey_key, data: data?.data }});

        const newSurveyObject = { id: surveyId, userId, recordId: data?.record_id, doors: {}, type: 'online', surveyKey: data?.survey_key };

        if (survey) {
            survey[surveyId] = newSurveyObject;

            localStorage.setItem('survey', JSON.stringify(survey));
        } else {
            localStorage.setItem('survey', JSON.stringify({ [surveyId] : newSurveyObject }));
        }

        let clientData = JSON.parse(localStorage.getItem('clientData'));

        if (clientData) {
            clientData[surveyId] = data?.fields;
            localStorage.setItem('clientData', JSON.stringify(clientData));
        } else {
            localStorage.setItem('clientData', JSON.stringify({ [surveyId]: data?.fields }));
        }

    } catch (error) {
        const data = { id: surveyId, userId, doors: {}, type: 'offline' };
                
        dispatch({ type: CREATE_SURVEY_FAILURE, payload: data });

        if (survey) {
            survey[surveyId] = data;
            localStorage.setItem('survey', JSON.stringify(survey));
        } else {
            localStorage.setItem('survey', JSON.stringify({ [surveyId]: data }));
        }

        let clientData = JSON.parse(localStorage.getItem('clientData'));

        if (clientData) {
            clientData[surveyId] = formPayload;
            localStorage.setItem('clientData', JSON.stringify(clientData));
        } else {
            localStorage.setItem('clientData', JSON.stringify({ [surveyId]: formPayload }));
        }
    }

    localStorage.setItem('activeSurvey', surveyId);
};

export const createSurveyEntry = (surveyId) => async (dispatch, getState) => {
    const state = getState();
    const { app: { sections } } = state;

    const surveyEntryId = uuidv4();

    let data = { 
        id: 0,
        parentId: 0,
        recordId: 0,
        ref: "",
        leftId: null,
        type: "",
        answers: _.cloneDeep(sections),
        other: {
            text: '',
            cost: 0.00
        },
        photographs: {
            photograph_1: '',
            photograph_2: ''
        },
        sort: 0,
        deleted: false
    };

    dispatch({ type: CLEAR_DOORS });

    try {
        dispatch({ type: CREATE_SURVEY_ENTRY_REQUEST });

        const { data: entryData } = await axios.post('https://app.webglu21.co.uk/app_handler.php', {}, { headers: { 'WG-Method': 'SAVE_SURVEY_ENTRY_FORM', 'Wg-SurveyId': surveyId, 'WG-Key': Cookie.get('accessToken') }});

        const payload = { id: surveyEntryId, surveyId, userId: entryData?.customer_id, recordId: entryData?.record_id, surveyKey: entryData?.survey_key, clientData: entryData?.data };

        dispatch({ type: CREATE_SURVEY_ENTRY_SUCCESS, payload: payload });

        if (entryData) {
            dispatch({ type: POPULATE_DOORS_REQUEST });

            const { data: result } = await axios.post('https://app.webglu21.co.uk/app_handler.php', data, { headers: { 'WG-Method': 'LOAD_SURVEY_DOOR_TEMPLATE', 'Wg-Entryid': entryData?.record_id, 'Wg-Surveyid': surveyId, 'WG-Key': Cookie.get('accessToken') }});

            dispatch({ type: POPULATE_SURVEY_DOORS, payload: result?.doors });
            dispatch({ type: POPULATE_DOORS_SUCCESS, payload: result?.doors });

            let survey = JSON.parse(localStorage.getItem('survey'));

            if (survey) {
                survey[surveyEntryId] = payload;
    
                localStorage.setItem('survey', JSON.stringify(survey));
            } else {
                localStorage.setItem('survey', JSON.stringify({ [surveyEntryId] : payload }));
            }

            let clientData = JSON.parse(localStorage.getItem('clientData'));

            if (clientData) {
                clientData[surveyEntryId] = entryData?.client_data;
                localStorage.setItem('clientData', JSON.stringify(clientData));
            } else {
                localStorage.setItem('clientData', JSON.stringify({ [surveyEntryId]: entryData?.client_data }));
            }

            
            let doors = JSON.parse(localStorage.getItem('doors'));

            if (doors) {
                doors[surveyEntryId] = result?.doors;
                localStorage.setItem('doors', JSON.stringify(doors));
            } else {
                localStorage.setItem('doors', JSON.stringify({ [surveyEntryId]: result?.doors }));
            }
            
            localStorage.setItem('clientDataDisplay', JSON.stringify(entryData?.data));
            localStorage.setItem('activeSurvey', surveyEntryId);
        }

    } catch (error) {
        dispatch({ type: CREATE_SURVEY_ENTRY_FAILURE, payload: `/survey/${surveyId}` });
        dispatch({ type: POPULATE_DOORS_FAILURE });
    }
};

export const updateSurvey = (hotel, name, type, frequency, recordId) => async dispatch => {
    const formPayload = {
        hotel_name: hotel,
        survey_name: name,
        survey_type: type,
        survey_frequency: frequency,
    };

    const fd = new FormData();

    fd.append('hotel_name', hotel);
    fd.append('survey_name', name);
    fd.append('survey_type', type);
    fd.append('survey_frequency', frequency);
    
    const surveyId = localStorage.getItem('activeSurvey');

    try {
        dispatch({ type: UPDATE_SURVEY_REQUEST });

        const { data } = await axios.post('https://app.webglu21.co.uk/app_handler.php', fd, { headers: { 'WG-Method': 'SAVE_SURVEY_FORM', 'WG-Key': Cookie.get('accessToken'), 'WG-RecordId': recordId }});

        dispatch({ type: UPDATE_SURVEY_SUCCESS, payload: { recordId: data?.record_id, surveyKey: data?.survey_key }});

        let clientData = JSON.parse(localStorage.getItem('clientData'));

        if (clientData) {
            clientData[surveyId] = formPayload;
            localStorage.setItem('clientData', JSON.stringify(clientData));
        } else {
            localStorage.setItem('clientData', JSON.stringify({ [surveyId]: formPayload }));
        }
    } catch (error) {

    }
}

export const loadDoor = (id) => async dispatch => {
    try {
        dispatch({ type: LOAD_DOOR_REQUEST });

        const { data } = await axios.get('https://app.webglu21.co.uk/app_handler.php', { headers: { 'WG-Method': 'GET_DOOR_TEMPLATE', 'Wg-EntryId': id, 'WG-Key': Cookie.get('accessToken') }});

        dispatch({ type: LOAD_DOOR_SUCCESS, payload: data });
    } catch (error) {
        const dependencies = JSON.parse(localStorage.getItem('dependencies'));
        
        dispatch({ type: LOAD_DOOR_FAILURE, payload: dependencies?.form_templates?.door });
    }
};

export const removeAllDoors = (recordId) => async dispatch => {
    try {
        dispatch({ type: REMOVE_ALL_DOORS_REQUEST });

        await axios.get('https://app.webglu21.co.uk/app_handler.php', { headers: { 'WG-Method': 'REMOVE_ALL_DOORS', 'Wg-RecordId': recordId, 'WG-Key': Cookie.get('accessToken') }});
        
        dispatch({ type: REMOVE_ALL_DOORS_SUCCESS });
    } catch (error) {
        dispatch({ type: REMOVE_ALL_DOORS_FAILURE, payload: error.message });
    }
};

export const clearDoorProceed = () => async dispatch => {
    dispatch({ type: CLEAR_DOOR_PROCEED });
};

export const saveDoorRef = (id, ref) => dispatch => {
    try {
        dispatch({ type: SAVE_DOOR_REF_REQUEST });
        dispatch({ type: SAVE_DOOR_REF_SUCCESS, payload: { id, ref } });
    } catch (error) {
        dispatch({ type: SAVE_DOOR_REF_FAILURE });
    }

    let survey = JSON.parse(localStorage.getItem('survey'));
    const activeSurvey = localStorage.getItem('activeSurvey');

    console.log(survey);
    console.log(activeSurvey);

    if (survey[activeSurvey]) {
        console.log('here 3')
        console.log(survey[activeSurvey])
        if (survey[activeSurvey]?.doors) {
            console.log('here 4')
            if (survey[activeSurvey]?.doors[id]) {
                survey[activeSurvey].doors[id].reference = ref;

                if (survey[activeSurvey].doors[id]?.data) {
                    console.log('here 1');
                    if (survey[activeSurvey].doors[id]?.data['door-reference_5f7c9de1088a0_formid_436']) {
                        console.log('here 2');
                        console.log(survey[activeSurvey].doors[id]?.data);
                        console.log(ref);
                        survey[activeSurvey].doors[id].data['door-reference_5f7c9de1088a0_formid_436'].value = ref;
                    }
                }
            }
        }
    }

    localStorage.setItem('survey', JSON.stringify(survey));
}

export const goToPDFSelector = () => dispatch => {
    dispatch({ type: SET_PDF_SELECTOR_TRUE });
}