import _ from 'lodash';

import {
    LOAD_DOORS,
    SAVE_SECTION_REQUEST,
    SAVE_SECTION_SUCCESS,
    SAVE_DOOR_OTHER_REQUEST,
    SAVE_DOOR_OTHER_SUCCESS,
    ADD_DOOR_REQUEST,
    ADD_DOOR_SUCCESS,
    SAVE_DOOR_REF_SUCCESS,
    SAVE_DOOR_REF_REQUEST,
    SAVE_DOOR_TYPE_REQUEST,
    SAVE_DOOR_TYPE_SUCCESS,
    CLEAR_DOORS,
    REMOVE_DOOR_REQUEST,
    REMOVE_DOOR_SUCCESS,
    RESTORE_DOOR_REQUEST,
    RESTORE_DOOR_SUCCESS,
    SAVE_TO_LOCALSTORAGE_REQUEST,
    SAVE_TO_LOCALSTORAGE_SUCCESS,
    LOAD_DOOR_REPAIRS_REQUEST,
    LOAD_DOOR_REPAIRS_SUCCESS,
    POPULATE_DOORS,
    SAVE_DOOR_REPAIR_SUCCESS,
    SAVE_DOOR_REPAIR_REQUEST,
    RESET_DOOR_ANSWER,
    DOOR_REDIRECT,
    CLEAR_DOOR_REDIRECT,
    SAVE_DOOR_PHOTO_REQUEST,
    SAVE_DOOR_PHOTO_SUCCESS,
    REMOVE_DOOR_PHOTO_SUCCESS,
    DUPLICATE_DOOR_SUCCESS,
    SAVE_DOOR_PHOTO_FAILURE,
    POPULATE_DOORS_SUCCESS,
    POPULATE_DOORS_REQUEST,
    POPULATE_DOORS_FAILURE
} from "../types/doorTypes";

let doors = {};

const savedDoors = JSON.parse(localStorage.getItem('doors'));
const activeSurvey = localStorage.getItem('activeSurvey');

if (savedDoors) {
    doors = savedDoors[activeSurvey] || {};
}

const initialState = {
    doors,
    repairs: [],
    redirectToNextDoor: '',
    loading: false,
    refLoading: false,
    otherLoading: false,
    repairsLoading: false,
    photoLoading: false,
    saveToLocalStorage: false,
    error: null,
    refError: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_DOORS:
            return {
                ...state,
                doors: action.payload,
            }
        case CLEAR_DOORS:
            return {
                ...state,
                loading: false,
                doors: {},
                error: null,
            }
        case ADD_DOOR_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case ADD_DOOR_SUCCESS: 
            return {
                ...state,
                doors: {
                    ...state.doors,
                    [action.payload.id]: {
                        ...action.payload,
                    }
                },
                loading: false
            }
        case SAVE_SECTION_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case SAVE_SECTION_SUCCESS:
            return {
                ...state,
                doors: {
                    ...state.doors,
                    [action.payload.id]: {
                        ...state.doors[action.payload.id],
                        ...action.payload
                    }
                },
                loading: false,
            }
        case SAVE_DOOR_REF_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }
        case SAVE_DOOR_REF_SUCCESS:
            let rightDoor = _.values(state.doors).find((door) => door.leftId === action.payload.id);

            if (rightDoor) {
                return {
                    ...state,
                    doors: {
                        ...state.doors,
                        [action.payload.id]: {
                            ...state.doors[action.payload.id],
                            ref: action.payload.ref,
                        },
                        [rightDoor.id]: {
                            ...state.doors[rightDoor.id],
                            ref: action.payload.ref,
                        }
                    },
                    loading: false,
                }
            }

            return {
                ...state,
                doors: {
                    ...state.doors,
                    [action.payload.id]: {
                        ...state.doors[action.payload.id],
                        ref: action.payload.ref,
                    }
                },
                loading: false,
            }
        case SAVE_DOOR_TYPE_REQUEST:
            return {
                ...state,
                refLoading: true,
            }
        case SAVE_DOOR_TYPE_SUCCESS:
            return {
                ...state,
                refLoading: false,
                doors: {
                    ...state.doors,
                    [action.payload.id]: {
                        ...state.doors[action.payload.id],
                        type: action.payload.type,
                    }
                }
            }
        case SAVE_DOOR_OTHER_REQUEST:
            return {
                ...state,
                otherLoading: true,
            }
        case SAVE_DOOR_OTHER_SUCCESS:
            return {
                ...state,
                otherLoading: false,
                doors: {
                    ...state.doors,
                    [action.payload.id]: {
                        ...state.doors[action.payload.id],
                        other: action.payload.other,
                    }
                }
            }
        case REMOVE_DOOR_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case REMOVE_DOOR_SUCCESS:
            if (action.payload.rightDoor) {
                return {
                    ...state,
                    loading: false,
                    doors: {
                        ...state.doors,
                        [action.payload.doorId]: {
                            ...state.doors[action.payload.doorId],
                            deleted: true,
                        },
                        [action.payload.rightDoor.id]: {
                            ...state.doors[action.payload.rightDoor.id],
                            deleted: true,
                        }
                    }
                }
            }

            return {
                ...state,
                loading: false,
                doors: {
                    ...state.doors,
                    [action.payload.doorId]: {
                        ...state.doors[action.payload.doorId],
                        deleted: true,
                    }
                }
            }
        case SAVE_DOOR_PHOTO_REQUEST:
            return {
                ...state,
                photoLoading: true,
            }
        case SAVE_DOOR_PHOTO_SUCCESS:
            return {
                ...state,
                photoLoading: false,
                doors: {
                    ...state.doors,
                    [action.payload.id]: {
                        ...state.doors[action.payload.id],
                        photographs: {
                            ...state.doors[action.payload.id].photographs,
                            [action.payload.name]: action.payload.value,
                        }
                    }
                }
            }
        case SAVE_DOOR_PHOTO_FAILURE:
            return {
                ...state,
                photoLoading: false,
            }
        case REMOVE_DOOR_PHOTO_SUCCESS:
            return {
                ...state,
                doors: {
                    ...state.doors,
                    [action.payload.id]: {
                        ...state.doors[action.payload.id],
                        photographs: {
                            ...state.doors[action.payload.id].photographs,
                            [action.payload.name]: ''
                        }
                    }
                }
            }
        case RESTORE_DOOR_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case RESTORE_DOOR_SUCCESS:
            return {
                ...state,
                loading: false,
                doors: {
                    ...state.doors,
                    [action.payload]: {
                        ...state.doors[action.payload],
                        deleted: false,
                    }
                }
            }
        case SAVE_TO_LOCALSTORAGE_REQUEST:
            return {
                ...state,
                saveToLocalStorage: true,
            }
        case SAVE_TO_LOCALSTORAGE_SUCCESS:
            return {
                ...state,
                saveToLocalStorage: false,
            }
        case LOAD_DOOR_REPAIRS_REQUEST:
            return {
                ...state,
            }
        case LOAD_DOOR_REPAIRS_SUCCESS:
            return {
                ...state,
                repairs: action.payload?.repairs,
            }
        case SAVE_DOOR_REPAIR_REQUEST:
            return {
                ...state,
                repairsLoading: true,
            }
        case SAVE_DOOR_REPAIR_SUCCESS:
            return {
                ...state,
                repairsLoading: false,
                // repairs: [...state.repairs.filter((r) => r.appointment_id === action.payload.appointment_id)],
            }
        case POPULATE_DOORS_REQUEST:
            return {
                ...state,
                loading: true,
                doors: {},
            }
        case POPULATE_DOORS_SUCCESS:
            return {
                ...state,
                loading: false,
                doors: action.payload,
            }
        case POPULATE_DOORS_FAILURE:
            return {
                ...state,
                loading: false,
            }
        case DUPLICATE_DOOR_SUCCESS:
            return {
                ...state,
                doors: {
                    ...state.doors,
                    ...action.payload
                }
            }
        case RESET_DOOR_ANSWER:
            return {
                ...state,
                loading: false,
                doors: action.payload,
            }
        case DOOR_REDIRECT:
            return {
                ...state,
                redirectToNextDoor: action.payload,
            }
        case CLEAR_DOOR_REDIRECT:
            return {
                ...state,
                redirectToNextDoor: ''
            }
        default:
            return state;
    }
};