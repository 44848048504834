export const SAVE_DOOR_REPAIR_REQUEST = 'SAVE_DOOR_REPAIR_REQUEST';
export const SAVE_DOOR_REPAIR_SUCCESS = 'SAVE_DOOR_REPAIR_SUCCESS';
export const SAVE_DOOR_REPAIR_FAILURE = 'SAVE_DOOR_REPAIR_FAILURE';
export const SAVE_DOOR_REPAIR_RESET = 'SAVE_DOOR_REPAIR_RESET';

export const LOAD_REPAIRS_REQUEST = 'LOAD_REPAIRS_REQUEST';
export const LOAD_REPAIRS_SUCCESS = 'LOAD_REPAIRS_SUCCESS';
export const LOAD_REPAIRS_FAILURE = 'LOAD_REPAIRS_FAILURE';

export const LOAD_ALL_REPAIRS_REQUEST = 'LOAD_ALL_REPAIRS_REQUEST';
export const LOAD_ALL_REPAIRS_SUCCESS = 'LOAD_ALL_REPAIRS_SUCCESS';
export const LOAD_ALL_REPAIRS_FAILURE = 'LOAD_ALL_REPAIRS_FAILURE';

export const LOAD_REPAIR_HISTORY_REQUEST = 'LOAD_REPAIR_HISTORY_REQUEST';
export const LOAD_REPAIR_HISTORY_SUCCESS = 'LOAD_REPAIR_HISTORY_SUCCESS';
export const LOAD_REPAIR_HISTORY_FAILURE = 'LOAD_REPAIR_HISTORY_FAILURE';

export const LATEST_SURVEY_REQUEST = 'LATEST_SURVEY_REQUEST';
export const LATEST_SURVEY_SUCCESS = 'LATEST_SURVEY_SUCCESS';
export const LATEST_SURVEY_FAILURE = 'LATEST_SURVEY_FAILURE';