import React from 'react';
import { useSelector } from 'react-redux';

import { COSTS_HEADINGS } from '../../constants/costs';
import Input from '../forms/Input';

export default function CostSection({ label, field, handleChange }) {
    const costsSelector = useSelector((state) => state.costs);
    const { costs } = costsSelector;

    return (
        <>
            {label && (<div className="form__title">{label}</div>)}
            {Object.keys(COSTS_HEADINGS).map((heading) => {
                let value = '';

                if (costs) {
                    if (costs[field?.name]) {
                        value = costs[field?.name][heading] || '';
                    }
                }

                console.log(value);

                const newField = { ...field, labelClass: 'costLabel', label: COSTS_HEADINGS[heading], name: `${field?.name}_${heading.substring(0,1)}` };

                return <Input field={newField} value={value} handleChange={handleChange} />
            })}
        </>
    )
}