export const GET_RECENT_SURVEYS_REQUEST = 'GET_RECENT_SURVEYS_REQUEST';
export const GET_RECENT_SURVEYS_SUCCESS = 'GET_RECENT_SURVEYS_SUCCESS';
export const GET_RECENT_SURVEYS_FAILURE = 'GET_RECENT_SURVEYS_FAILURE';

export const GET_SURVEYS_DUE_REQUEST = 'GET_SURVEYS_DUE_REQUEST';
export const GET_SURVEYS_DUE_SUCCESS = 'GET_SURVEYS_DUE_SUCCESS';
export const GET_SURVEYS_DUE_FAILURE = 'GET_SURVEYS_DUE_FAILURE';

export const SURVEY_SEARCH_REQUEST = 'SURVEY_SEARCH_REQUEST';
export const SURVEY_SEARCH_SUCCESS = 'SURVEY_SEARCH_SUCCESS';
export const SURVEY_SEARCH_FAILURE = 'SURVEY_SEARCH_FAILURE';

export const OFFLINE_SURVEY_REQUEST = 'OFFLINE_SURVEY_REQUEST';
export const OFFLINE_SURVEY_SUCCESS = 'OFFLINE_SURVEY_SUCCESS';
export const OFFLINE_SURVEY_FAILURE = 'OFFLINE_SURVEY_FAILURE';