import axios from 'axios';
import Cookie from 'js-cookie';
import _ from 'lodash';

import { GET_RECENT_SURVEYS_REQUEST, GET_RECENT_SURVEYS_SUCCESS, GET_SURVEYS_DUE_REQUEST, GET_SURVEYS_DUE_SUCCESS, GET_SURVEYS_DUE_FAILURE, SURVEY_SEARCH_REQUEST, SURVEY_SEARCH_SUCCESS, SURVEY_SEARCH_FAILURE, OFFLINE_SURVEY_REQUEST, OFFLINE_SURVEY_SUCCESS, OFFLINE_SURVEY_FAILURE, GET_RECENT_SURVEYS_FAILURE } from '../types/dashboardTypes';

export const getRecentSurveys = (limit = 5) => async dispatch => {
    try {
        dispatch({ type: GET_RECENT_SURVEYS_REQUEST });

        const { data } = await axios.get('https://app.webglu21.co.uk/app_handler.php', { headers: { 'WG-Method': 'DASHBOARD_RECENT_SURVEYS', 'WG-Key': Cookie.get('accessToken'), 'Wg-Limit': limit }});

        dispatch({ type: GET_RECENT_SURVEYS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: GET_RECENT_SURVEYS_FAILURE });
    }
};

export const getOfflineSurveys = () => dispatch => {
    try {
        dispatch({ type: OFFLINE_SURVEY_REQUEST });

        const surveys = JSON.parse(localStorage.getItem('survey'));
        const clientData = JSON.parse(localStorage.getItem('clientData'));

        const offlineSurveys = _.values(surveys)
                                .filter((survey) => survey.type === "offline")
                                .map((survey) => {
                                    return {
                                        ...survey,
                                        ...clientData[survey.id],
                                    }
                                });

        dispatch({ type: OFFLINE_SURVEY_SUCCESS, payload: offlineSurveys });
    } catch (error) {
        console.error(error);
        dispatch({ type: OFFLINE_SURVEY_FAILURE });
    }
};

export const getSurveysDue = (days = 30, limit = 5) => async dispatch => {
    try {
        dispatch({ type: GET_SURVEYS_DUE_REQUEST });

        const { data } = await axios.get('https://app.webglu21.co.uk/app_handler.php', { headers: { 'WG-Method': 'DASHBOARD_SURVEYS_DUE', 'Wg-Days': days, 'Wg-Limit': limit, 'WG-Key': Cookie.get('accessToken') }});

        dispatch({ type: GET_SURVEYS_DUE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: GET_SURVEYS_DUE_FAILURE });
    }
};

export const surveySearch = (query) => async dispatch => {
    try {
        dispatch({ type: SURVEY_SEARCH_REQUEST });

        const { data } = await axios.get('https://app.webglu21.co.uk/app_handler.php', { headers: { 'WG-Method': 'SURVEY_SEARCH', 'Wg-Query': query, 'WG-Key': Cookie.get('accessToken') }});

        dispatch({ type: SURVEY_SEARCH_SUCCESS, payload: { results: _.values(data?.search_results), count: data?.count }});
    } catch (error) {
        dispatch({ type: SURVEY_SEARCH_FAILURE, payload: error });
    }
};

