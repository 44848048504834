import React from 'react';
import ClipLoader from "react-spinners/ClipLoader";

import styles from './styles/Loading.module.css';

export default function Loading() {
    return (
        <div className={styles.background}>
            <ClipLoader color="#fff" loading={true} size={150} />
        </div>
    );
}