import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';

import CostSection from '../../components/CostSection';
import { setCosts } from '../../actions/costsActions';
import { COSTS_HEADINGS } from '../../constants/costs';

import './styles/Costs.css';

export const Costs = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [formData, setFormData] = useState(null);
    const [formFields, setFormFields] = useState([]);
    
    const costsSelector = useSelector((state) => state.costs);
    const { costs, form } = costsSelector;

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({...formData, [name]: value });
    }

    const onSubmit = (event) => {
        event.preventDefault();

        dispatch(setCosts(formData));
        history.push('/');
    }

    useEffect(() => {
        if (formFields) {
            let formTmp = {};

            if (costs) {
                for (const [key, value] of Object.entries(costs)) {
                    if (formFields.includes(key)) {
                        Object.keys(COSTS_HEADINGS).forEach((costKey) => {
                            let keyTmp = `${key}_${costKey.substring(0,1)}`;

                            formTmp[keyTmp] = value[costKey] || '';
                        });
                    }
                }
            }
    
            setFormData(formTmp);
        }
    }, [costs, formFields]);

    useEffect(() => {
        const tmpFields = form.map((field) => {
            return field?.name
        });

        setFormFields(tmpFields);
    }, [form]);

    return (
        <Fragment>
            <Header>Costs Set Up</Header>
            <Menu />
            <Content style={{ marginTop: '100.27px' }} >
                <h2 style={{ textAlign: 'center' }}>Please specify the costs applicable for any door repairs</h2>
                <form className="costsForm" onSubmit={onSubmit}>
                    <Fragment>
                        {form?.map((field) => (                             
                            <CostSection label={field?.section_label} field={field} handleChange={handleChange} />
                        ))}
                        <div className="form__buttons">
                            <button className="form__button" type="submit">Save &amp; Continue</button>
                        </div>
                    </Fragment>
                </form>
            </Content>
            <BottomNav />
        </Fragment>
    )
}
