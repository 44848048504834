import { GET_COSTS_DEPENDENCIES_REQUEST, GET_COSTS_DEPENDENCIES_SUCCESS, GET_COSTS_DEPENDENCIES_FAIL, LOAD_COSTS_REQUEST, LOAD_COSTS_SUCCESS, LOAD_COSTS_FAILURE, SET_COSTS_REQUEST, SET_COSTS_SUCCESS, SET_COSTS_FAILURE, GET_COSTS_REQUEST, GET_COSTS_SUCCESS, GET_COSTS_FAILURE } from '../types/costsTypes';

const costsObj = JSON.parse(localStorage.getItem('costs'));
const dependencies = JSON.parse(localStorage.getItem('dependencies'));
const costsCount = localStorage.getItem('costsCount');

const initialState = {
    form: dependencies?.form_templates?.costs || [],
    costs: costsObj || null,
    costsCount: costsCount || 0,
    loading: false,
    errors: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_COSTS_REQUEST:
            return {
                ...state,
                loading: true,
                errors: null
            }
        case SET_COSTS_SUCCESS:
            return {
                ...state,
                loading: false,
                costs: action.payload.fields,
                costsCount: action.payload.count
            }
        case SET_COSTS_FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.payload.error,
                costs: action.payload.data
            }
        case GET_COSTS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_COSTS_SUCCESS:
            return {
                ...state,
                loading: false,
                costs: action.payload
            }
        case GET_COSTS_FAILURE:
            return {
                ...state,
                loading: false
            }
        case GET_COSTS_DEPENDENCIES_REQUEST:
        case GET_COSTS_DEPENDENCIES_FAIL:
            return {
                ...state,
                form: []
            };
        case GET_COSTS_DEPENDENCIES_SUCCESS:
            return {
                ...state,
                form: action.payload?.form_templates?.costs
            };
        default:
            return state;
    }
};