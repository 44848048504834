import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';
import LoadingSkeleton from '../../components/LoadingSkeleton';

import { getSurveysDue, surveySearch } from '../../actions/dashboardActions';

import styles from './AllSurveys.module.css';

export default function AllSurveys() {
    const dispatch = useDispatch();
    const history = useHistory();

    const [search, setSearch] = useState('');
    const [surveyHeading, setSurveyHeading] = useState('Previous Surveys');

    const dashboardSelector = useSelector((state) => state.dashboard);
    const { loadingSurveysDue, surveysDue } = dashboardSelector;

    const handleSearch = () => {
        if (search) {
            dispatch(surveySearch(search));
            setSurveyHeading('Search Results');
        }
    };

    useEffect(() => {
        dispatch(getSurveysDue(365, 1000));
    }, [dispatch]);

    return (
        <Fragment>
            <Header>Door Survey App</Header>
            <Menu />
            <Content style={{ marginTop: '100.27px' }} >
                <div className={styles.dashboardHeader}>
                    <button className={styles.newSurveyButton} onClick={() => history.push('/survey/new')}>Create New Survey</button>
                    <button className={styles.previousSurveyButton} onClick={() => history.push('/')}>Back to Dashboard</button>
                </div>
                {loadingSurveysDue ? (
                    <LoadingSkeleton />
                ) : (
                    <>
                        <div>
                            <div className={styles.surveysDueHeader}>
                                <h2 className={styles.surveyHeading}>{surveyHeading}</h2>
                                <div>
                                    <input className={styles.surveysDueSearchInput} type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
                                    <button className={styles.surveysDueSearchButton} onClick={handleSearch}>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </button>
                                </div>
                            </div>
                            <div>
                                {surveysDue.length > 0 ? surveysDue.map((survey) => (
                                    <div key={survey?.record_id} className={styles.surveyRow}>
                                        <div className={styles.hotelName}>{survey?.hotel_name} - {survey?.survey_name}</div>
                                        <div className={styles.surveyType}>{survey?.survey_type}</div>
                                        <div className={styles.surveyStatus}>{survey?.due_status}</div>
                                        <div className={styles.actions}>
                                            <Link className={styles.editRowButton} to={`/survey/${survey?.record_id}`}>View</Link>
                                        </div>
                                    </div>
                                )) : (
                                    <div className={styles.surveysNotFound}>You have no surveys due.</div>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </Content>
            <BottomNav />
        </Fragment>
    )
}
