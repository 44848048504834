import axios from 'axios';
import Cookie from 'js-cookie';

import { clearSurvey } from '../functions/utils';

import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAIL, REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAIL, RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL, LOGOUT, CHECK_SUBSCRIPTION_REQUEST, CHECK_SUBSCRIPTION_SUCCESS, CHECK_SUBSCRIPTION_FAILURE } from '../types/authTypes';
import { CLEAR_SURVEY } from '../types/surveyTypes';
import { CLEAR_FLOORPLANS } from '../types/floorPlanTypes';

export const login = (email, password) => async dispatch => {
    try {
        dispatch({ type: LOGIN_REQUEST });

        const { data } = await axios.post('https://app.webglu21.co.uk/app_handler.php', { email, password }, { headers: { 'WG-Method': 'LOGIN' }});

        if (data?.jwt) {
            Cookie.set('accessToken', data.jwt, { expires: 30 });
        }
        
        if (data?.user) {
            Cookie.set('user', JSON.stringify(data.user), { expires: 30 });
        }

        dispatch({ type: LOGIN_SUCCESS, payload: data.user });

        if (data?.user?.customer_id) {
            const survey = JSON.parse(localStorage.getItem('survey'));

            if (survey?.userId && survey?.userId !== parseInt(data?.user?.customer_id)) {
                clearSurvey();
                dispatch({ type: CLEAR_SURVEY });
                dispatch({ type: CLEAR_FLOORPLANS });
            }
        }
    } catch (error) {
        dispatch({ type: LOGIN_FAIL, payload: error?.response?.data.errors });
    }
}

export const register = (firstname, lastname, email, repeatEmail, phone, repeatPhone, dob, password, repeatPassword) => async dispatch => {
    try {
        dispatch({ type: REGISTER_REQUEST });

        await axios.post('https://app.webglu21.co.uk/app_handler.php', { firstname, lastname, email, repeat_email: repeatEmail, phone, repeat_phone: repeatPhone, dob, password, repeat_password: repeatPassword }, { headers: { 'WG-Method': 'REGISTER' }});

        dispatch({ type: REGISTER_SUCCESS });
    } catch (error) {
        dispatch({ type: REGISTER_FAIL, payload: error?.response.data.errors });
    }
};

export const logout = () => dispatch => {
    dispatch({ type: LOGOUT });

    Cookie.remove('accessToken');
    Cookie.remove('user');
    localStorage.removeItem('recentSurveys');
    localStorage.removeItem('costs');
};

export const resetPassword = (email) => async dispatch => {
    try {
        dispatch({ type: RESET_PASSWORD_REQUEST });

        const { data } = await axios.post('https://app.webglu21.co.uk/app_handler.php', { email }, { headers: { 'WG-Method': 'RESET_PASSWORD' }});

        dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data.message });
    } catch (error) {
        dispatch({ type: RESET_PASSWORD_FAIL, payload: error?.response.data.errors });
    }
};

export const checkSubscription = () => async dispatch => {
    try {
        dispatch({ type: CHECK_SUBSCRIPTION_REQUEST });

        const { data } = await axios.get('https://app.webglu21.co.uk/app_handler.php', { headers: { 'WG-Method': 'CHECK_SUBSCRIPTION', 'WG-Key': Cookie.get('accessToken') }});

        dispatch({ type: CHECK_SUBSCRIPTION_SUCCESS, payload: data?.valid });
    } catch (error) {
        dispatch({ type: CHECK_SUBSCRIPTION_FAILURE });
    }
};