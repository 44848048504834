import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import ANSWERS from '../../constants/answers';

import styles from './styles/DoorViewQuestion.module.css';
import answers from '../../constants/answers';

export const DoorViewQuestion = ({ section: { id, question }, doorId, onAnswerQuestion, onFailedOption, onFailedOther, onShowControls }) => {
    const [showFailedOptions, setShowFailedOptions] = useState(false);
    const [otherCommentRequired, setOtherCommentRequired] = useState(false);
    const [otherCommentError, setOtherCommentError] = useState(false);
    const [failedOther, setFailedOther] = useState('');
    const [failedOption, setFailedOption] = useState('');
    const [failedOptionError, setFailedOptionError] = useState(false);
    const [active, setActive] = useState(0);
    const [urgent, setUrgent] = useState(false);

    const doorSelector = useSelector(state => state.door);
    const { doors } = doorSelector;

    const handleOther = () => {
        setOtherCommentRequired(true);
        setFailedOption(answers.OTHER);
        onFailedOption(id, ANSWERS.OTHER);
    };

    const handleOption = (option) => {
        setOtherCommentRequired(false);
        setOtherCommentError(false);
        onFailedOption(id, option);
        setFailedOption(option);
    }

    const handleOtherSave = () => {
        if (failedOption) {
            if (otherCommentRequired) {
                if (failedOther) {
                    onFailedOther(id, failedOther, urgent);
                } else {
                    setOtherCommentError(true);
                }
            } else {
                onFailedOther(id, failedOther, urgent);
            }
        } else {
            setFailedOptionError(true);
        }
    };

    useEffect(() => {
        setShowFailedOptions(false);
        setOtherCommentRequired(false);
        setOtherCommentError(false);
        setFailedOptionError(false);
        setFailedOption('');
        setUrgent(false);
        setActive(0);

        const currentAnswer = doors[doorId]?.answers.find((answer) => answer.id === id);

        if (currentAnswer) {
            // set if failed other text exists
            if (currentAnswer?.failedOther) {
                setFailedOther(currentAnswer?.failedOther);
            } else {
                setFailedOther('');
            }
    
            if (currentAnswer?.failedOption === answers.REPLACE) {
                setActive(1);
                setShowFailedOptions(true);
                setFailedOption(answers.REPLACE);
            } else if (currentAnswer?.failedOption === answers.REPAIR) {
                setActive(2);
                setShowFailedOptions(true);
                setFailedOption(answers.REPAIR);
            } else if (currentAnswer?.failedOption === answers.ADJUST) {
                setActive(3);
                setShowFailedOptions(true);
                setFailedOption(answers.ADJUST);
            } else if (currentAnswer?.failedOption === answers.OTHER) {
                setActive(4);
                setShowFailedOptions(true);
                setOtherCommentRequired(false);
                setFailedOption(answers.OTHER);
            }

            if (currentAnswer?.urgent) {
                setUrgent(true);
            }
        }

    }, [id]);

    return (
        <div className={styles.background} onClick={onShowControls}>
            <div className={styles.sectionContainer} onClick={(e) => e.stopPropagation()}>
                <h3 className={styles.sectionHeading}>{question}</h3>
                <div className={styles.sectionButtons} style={{ marginTop: '1rem' }}>
                    <button className={styles.tickButton} onClick={(e) => { 
                        e.stopPropagation();
                        onAnswerQuestion(id, ANSWERS.PASSED);
                    }}><FontAwesomeIcon icon={faCheck} className={styles.buttonIcon} /></button>
                    <button className={styles.crossButton} onClick={(e) => {
                        e.stopPropagation();
                        setShowFailedOptions(true);
                    }}><FontAwesomeIcon icon={faTimes} className={styles.buttonIcon} /></button>
                    <button className={styles.naButton} onClick={(e) => {
                        e.stopPropagation();
                        onAnswerQuestion(id, ANSWERS.NA);
                    }}>NA</button>
                </div>
                {showFailedOptions && (
                    <>
                        <div className={styles.sectionButtons}>
                            <button className={`${styles.failedOptionButton} ${active === 1 ? styles.activeOption : ''}`} onClick={(e) => {
                                e.stopPropagation();
                                handleOption(ANSWERS.REPLACE);
                                setActive(1);
                            }}>Replace</button>
                            <button className={`${styles.failedOptionButton} ${active === 2 ? styles.activeOption : ''}`} onClick={(e) => {
                                e.stopPropagation();
                                handleOption(ANSWERS.REPAIR);
                                setActive(2);
                            }}>Repair</button>
                        </div>
                        <div className={styles.sectionButtons}>
                            <button className={`${styles.failedOptionButton} ${active === 3 ? styles.activeOption : ''}`} onClick={(e) => {
                                e.stopPropagation();
                                handleOption(ANSWERS.ADJUST);
                                setActive(3);
                            }}>Adjust</button>
                            <button className={`${styles.failedOptionButton} ${active === 4 ? styles.activeOption : ''}`} onClick={(e) => {
                                e.stopPropagation();
                                handleOther();
                                setActive(4);
                            }}>Other</button>
                        </div>
                        {failedOptionError && (
                            <div className={styles.failedOptionError}>Please select a failed option</div>
                        )}
                        <div style={{ marginTop: '1rem' }}>
                            <textarea className={styles.failedOther} onChange={(e) => setFailedOther(e.target.value)} value={failedOther}></textarea>
                            {otherCommentError && (
                                <div className={styles.commentError}>Comment is required</div>
                            )}
                            <div className={styles.urgentButtonContainer}>
                                <button className={`${styles.urgentButton} ${urgent ? styles.urgentButtonActive : ''}`} onClick={() => setUrgent(prev => !prev)}>Urgent</button>
                            </div>
                            <div className={styles.sectionButtons} style={{ justifyContent: 'center', marginTop: '1rem' }}>
                                <button className={styles.saveOtherButton} onClick={handleOtherSave}>Save</button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

