import React from 'react';
import _ from 'lodash';

import DoorRepairs from "../DoorRepairs";

import styles from './SurveyRepairs.module.css';

export default function SurveyRepairs({ label, doors, surveyId }) {
    return <div className={styles.repairsContainer}>
        <div>{label}</div>
        <div className={styles.doorContainer}>
            {doors && _.values(doors).map((door) => (
                <DoorRepairs key={door?.id} door={door} surveyId={surveyId} />
            ))}
        </div>
    </div>
}