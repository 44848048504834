import React from 'react';

import EditRepair from '../EditRepair';

export default function DoorRepairs({ door, surveyId }) {
    return <div>
        {door?.repairs.map((repair) => {
            return <EditRepair key={repair?.appointment_id} id={repair?.primary_id} appointmentId={repair?.appointment_id} surveyId={surveyId} fieldId={repair?.field_id} doorRef={door?.ref} message={repair?.repair_message} urgent={repair?.urgent} noSave={true} />
        }
        )}
    </div>
}