import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faSearch, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';
import LoadingSkeleton from '../../components/LoadingSkeleton';

import { getOfflineSurveys, getRecentSurveys, getSurveysDue, surveySearch } from '../../actions/dashboardActions';
import { loadAllRepairs } from '../../actions/repairsActions';
import { loadSurvey, loadSurveyWithID } from '../../actions/surveyActions';
import { ReactComponent as OpenDoor } from '../../assets/open_door.svg';

import styles from './Dashboard.module.css';

export default function Dashboard() {
    const dispatch = useDispatch();
    const history = useHistory();

    const [search, setSearch] = useState('');
    const [hideViewMore, setHideViewMore] = useState(false);
    const [hideViewMoreRecentSurveys, setHideViewMoreRecentSurveys] = useState(false);
    const [surveyHeading, setSurveyHeading] = useState('Surveys Due');

    const dashboardSelector = useSelector((state) => state.dashboard);
    const { loading, loadingSurveysDue, surveysDue, recentSurveys, surveysDueCount, recentSurveysCount, offlineSurveys } = dashboardSelector;
    
    const repairsSelector = useSelector((state) => state.repairs);
    const { totalDoorsWithActionRequired } = repairsSelector;
    
    const authSelector = useSelector((state) => state.auth);
    const { user } = authSelector;

    const savedSurveys = JSON.parse(localStorage.getItem('survey')) || {};

    const handleSearch = () => {
        if (search) {
            dispatch(surveySearch(search));
            setSurveyHeading('Search Results');
            setHideViewMore(true);
        }
    };

    const handleViewMoreSurveysDue = () => {
        dispatch(getSurveysDue(365, 1000));
    };

    const handleViewMoreRecentSurveys = () => {
        dispatch(getRecentSurveys(1000));
        setHideViewMoreRecentSurveys(true);
    }

    const handleContinueSurvey = (id) => {
        dispatch(loadSurvey(id));

        history.push('/survey/doors');
    }

    const handleOfflineContinueSurvey = (id) => {
        dispatch(loadSurveyWithID(id));

        history.push('/survey/doors');
    }

    useEffect(() => {
        // get all repairs
        dispatch(loadAllRepairs());
        dispatch(getSurveysDue());
        dispatch(getRecentSurveys());
        dispatch(getOfflineSurveys());
    }, [dispatch]);

    return (
        <Fragment>
            <Header>Door Survey App</Header>
            <Menu />
            <Content style={{ marginTop: '100.27px' }} >
                <div className={styles.dashboardHeader}>
                    <button className={styles.newSurveyButton} onClick={() => history.push('/survey/new')}>Create New Survey</button>
                    <button className={styles.previousSurveyButton} onClick={() => history.push('/survey/all')}>View All Surveys</button>
                </div>
                {totalDoorsWithActionRequired > 0 && (
                    <div className={styles.repairsStatus}>There are currently {totalDoorsWithActionRequired} doors with outstanding repairs. <Link to={`/repairs`}>VIEW NOW</Link></div>
                )}
                {loadingSurveysDue ? (
                    <LoadingSkeleton />
                ) : (
                    <>
                        <div>
                            <div className={styles.surveysDueHeader}>
                                <h2 className={styles.surveyHeading}>{surveyHeading}</h2>
                                <div>
                                    <input className={styles.surveysDueSearchInput} type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
                                    <button className={styles.surveysDueSearchButton} onClick={handleSearch}>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </button>
                                </div>
                            </div>
                            <div className={styles.recentSurveyHeadings}>
                                <div className={styles.hotelName}>Name</div>
                                <div className={styles.surveyType}>Type</div>
                                <div className={styles.surveyDoors}><OpenDoor style={{ width: '14px' }} /></div>
                                <div className={styles.surveyFailed}><FontAwesomeIcon icon={faTimes} color="#CD2121" /></div>
                                <div className={styles.surveyPassed}><FontAwesomeIcon icon={faCheck} color="#4BC33E" /></div>
                                <div className={styles.surveyStatus}>Due</div>
                                <div className={styles.actions}></div>
                            </div>
                            <div>
                                {surveysDue.length > 0 ? surveysDue.map((survey) => (
                                    <div key={survey?.record_id} className={styles.surveyRow}>
                                        <div className={styles.hotelName}>{survey?.hotel_name} - {survey?.survey_name}</div>
                                        <div className={styles.surveyType}>{survey?.survey_type}</div>
                                        <div className={styles.surveyDoors}>{survey?.total_doors}</div>
                                        <div className={styles.surveyFailed}>{survey?.failed_doors}</div>
                                        <div className={styles.surveyPassed}>{survey?.passed_doors}</div>
                                        <div className={styles.surveyStatus}>{survey?.due_status}</div>
                                        <div className={styles.actions}>
                                            <Link className={styles.editRowButton} to={`/survey/${survey?.record_id}`}>View</Link>
                                        </div>
                                    </div>
                                )) : (
                                    <div className={styles.surveysNotFound}>You have no surveys due.</div>
                                )}
                                {surveysDueCount > surveysDue.length && !hideViewMore && (
                                    <div className={styles.viewMoreContainer}>
                                        <button onClick={handleViewMoreSurveysDue} className={styles.viewMoreButton}>View More</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}
                {loading ? (
                    <LoadingSkeleton />
                ) : (
                    <div className={styles.recentSurveysContainer}>
                        <div className={styles.recentSurveysHeader}>
                            <h2 className={styles.surveyHeading}>Recent Surveys</h2>
                        </div>
                        <div className={styles.recentSurveyHeadings}>
                            <div className={styles.hotelName}>Name</div>
                            <div className={styles.surveyType}>Type</div>
                            <div className={styles.surveyDoors}><OpenDoor style={{ width: '14px' }} /></div>
                            <div className={styles.surveyFailed}><FontAwesomeIcon icon={faTimes} color="#CD2121" /></div>
                            <div className={styles.surveyPassed}><FontAwesomeIcon icon={faCheck} color="#4BC33E" /></div>
                            <div className={styles.surveyStatus}>Status</div>
                            <div className={styles.actions}></div>
                        </div>
                        <div>
                            {recentSurveys.length > 0 ? recentSurveys.map((survey) => {
                                const surveyByKey = _.values(savedSurveys).find((s) => s.surveyKey === survey.survey_secret_key);

                                return (
                                    <div key={survey?.record_id} className={styles.recentSurveyRow}>
                                        <div className={styles.hotelName}>{survey?.hotel_name} - {survey?.survey_name}</div>
                                        <div className={styles.surveyType}>{survey?.survey_type}</div>
                                        <div className={styles.surveyDoors}>{survey?.total_doors}</div>
                                        <div className={styles.surveyFailed}>{survey?.failed_doors}</div>
                                        <div className={styles.surveyPassed}>{survey?.passed_doors}</div>
                                        <div className={styles.surveyStatus}>{survey?.survey_status}</div>
                                        <div className={styles.actions}>
                                            {survey?.survey_status === 'In Progress' && surveyByKey && (
                                                    <button className={styles.editRowButton} onClick={() => handleContinueSurvey(survey?.survey_secret_key)}>Continue</button>
                                                )}
                                            {survey?.survey_status === 'Completed' && (
                                                <a className={styles.editRowButton} href={`https://www.webglu21.co.uk/survey/hotel-v1/?${survey?.survey_secret_key}&${user?.secret_code}`} target="_blank" rel="noopener noreferrer">PDF <FontAwesomeIcon icon={faFilePdf} /></a>
                                            )}
                                        </div>
                                    </div>
                                )
                            }) : (
                                <div className={styles.surveysNotFound}>No recent surveys</div>
                            )}
                            {recentSurveysCount > surveysDue.length && !hideViewMoreRecentSurveys && (
                                <div className={styles.viewMoreContainer}>
                                    <button onClick={handleViewMoreRecentSurveys} className={styles.viewMoreButton}>View More</button>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {offlineSurveys.length > 0 && (       
                    <div>
                        <div className={styles.offlineSurveysHeading}>
                            <h2 className={styles.surveyHeading}>Offline Surveys</h2>
                        </div>
                        <div>
                            {offlineSurveys.map((survey) => (
                                <div key={survey?.id} className={styles.recentSurveyRow}>
                                    <div className={styles.offlineName}>{survey?.survey_name}</div>
                                    <div className={styles.offlineActions}>
                                        <button className={styles.editRowButton} onClick={() => handleOfflineContinueSurvey(survey.id)}>Continue</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </Content>
            <BottomNav />
        </Fragment>
    )
}
