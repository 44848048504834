import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PulseLoader from 'react-spinners/PulseLoader';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';
import { clearAllSurveys } from '../../functions/utils';
import { closeMenu } from '../../actions/appActions';

export const DataManagement = () => {
    const [loading, setLoading] = useState(false);
    const [showComplete, setShowComplete] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const dispatch = useDispatch();

    const handleDeleteData = () => {
        setLoading(true);
        dispatch(closeMenu());
        clearAllSurveys();
        setLoading(false);
        setConfirmation(false);
        setShowComplete(true);
    };

    useEffect(() => {
        dispatch(closeMenu());
        return () => {
            setShowComplete(false);
        };
    }, [dispatch]);

    return (
        <Fragment>
            <Header>Data Management</Header>
            <Menu />
            <Content style={{ marginTop: '100.27px' }}>
                <p style={{ textAlign: 'center' }}>
                    Would you like to delete all application data?
                    <br />
                    This will remove all surveys, photos, and data related to
                    surveys from the application
                </p>
                <div className="form__buttons">
                    <button
                        className="form__button"
                        onClick={() => setConfirmation(true)}
                        type="button"
                    >
                        Delete Data{' '}
                        <PulseLoader
                            loading={loading}
                            color={'#ffffff'}
                            css={'margin-left: 8px'}
                            size={5}
                        />
                    </button>
                </div>
                {showComplete && (
                    <p style={{ textAlign: 'center', marginTop: '1rem' }}>
                        All application data has been deleted
                    </p>
                )}
                {confirmation && (
                    <div class="popup">
                        <p className="title">
                            Are you sure you want to delete all application
                            data?
                        </p>
                        <div className="form__buttons">
                            <button
                                onClick={() => setConfirmation(false)}
                                className="form__button secondary"
                            >
                                No
                            </button>
                            <button
                                onClick={handleDeleteData}
                                className="form__button primary"
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                )}
            </Content>
            <BottomNav />
        </Fragment>
    );
};
