import { LOAD_RECENT_SURVEYS_FAILURE, LOAD_RECENT_SURVEYS_REQUEST, LOAD_RECENT_SURVEYS_SUCCESS, LOAD_SURVEYS_DUE_FAILURE, LOAD_SURVEYS_DUE_REQUEST, LOAD_SURVEYS_DUE_SUCCESS, LOAD_SURVEY_FAILURE, LOAD_SURVEY_REQUEST, LOAD_SURVEY_SUCCESS  } from '../types/surveyDashboardTypes';

const clientDataDisplay = JSON.parse(localStorage.getItem('clientDataDisplay'));

const initialState = {
    loading: false,
    errors: null,
    hotelName: clientDataDisplay?.hotel_name || '',
    surveyName: clientDataDisplay?.survey_name || '',
    repairsCount: 0,
    surveyId: null,
    surveysDue: [],
    recentSurveys: [],
    recentSurveysLoading: false,
    saveLoading: false,
    saveErrors: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_SURVEY_REQUEST:
            return {
                ...state,
                loading: true,
                errors: null
            }
        case LOAD_SURVEY_SUCCESS:
            return {
                ...state,
                loading: false,
                surveyName: action.payload.survey_name,
                hotelName: action.payload.hotel_name,
                repairsCount: action.payload.repair_count,
            }
        case LOAD_SURVEY_FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.payload,
            }
        case LOAD_RECENT_SURVEYS_REQUEST:
            return {
                ...state,
                recentSurveysLoading: true,
                recentSurveys: [],
            }
        case LOAD_RECENT_SURVEYS_SUCCESS:
            return {
                ...state,
                recentSurveysLoading: false,
                recentSurveys: [...action.payload.recent_surveys],
            }
        case LOAD_RECENT_SURVEYS_FAILURE:
            return {
                ...state,
                recentSurveysLoading: false,
            }
        case LOAD_SURVEYS_DUE_REQUEST:
            return {
                ...state,
                loading: true,
                surveysDue: [],
            }
        case LOAD_SURVEYS_DUE_SUCCESS:
            return {
                ...state,
                loading: false,
                surveysDue: [...action.payload],
            }
        case LOAD_SURVEYS_DUE_FAILURE: 
            return {
                ...state,
                loading: false,
            }
        default:
            return state;
    }
};