const DOOR_BODY = 548;
const DOOR_FRAME = 549;
const DOOR_CLOSER = 550;
const HINGES = 551;
const LOCK_LATCH = 552;
const STRIP = 553;
const GAP = 554;
const GLAZING = 555;
const THRESHOLD = 556;
const DOOR_SIGN = 571;
const DOOR_CLOSE_HOLD_POSITION = 572;
const PHOTOGRAPH_ONE = 567; 
const PHOTOGRAPH_TWO = 568;
const TOTAL_FIELDS = 11;

export default {
    DOOR_BODY,
    DOOR_FRAME,
    DOOR_CLOSER,
    HINGES,
    LOCK_LATCH,
    STRIP,
    GAP,
    GLAZING,
    THRESHOLD,
    DOOR_SIGN,
    DOOR_CLOSE_HOLD_POSITION,
    PHOTOGRAPH_ONE,
    PHOTOGRAPH_TWO,
    TOTAL_FIELDS,
};