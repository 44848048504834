import { GET_RECENT_SURVEYS_REQUEST, GET_RECENT_SURVEYS_SUCCESS, GET_RECENT_SURVEYS_FAILURE, GET_SURVEYS_DUE_REQUEST, GET_SURVEYS_DUE_SUCCESS, SURVEY_SEARCH_REQUEST, SURVEY_SEARCH_SUCCESS, SURVEY_SEARCH_FAILURE, OFFLINE_SURVEY_REQUEST, OFFLINE_SURVEY_SUCCESS, OFFLINE_SURVEY_FAILURE, GET_SURVEYS_DUE_FAILURE } from '../types/dashboardTypes';

const initialState = {
    surveysDue: [],
    surveysDueCount: 0,
    recentSurveys: [],
    recentSurveysCount: 0,
    offlineSurveys: [],    
    loading: false,
    loadingSurveysDue: false,
    removeSurveyLoading: false,
    errors: null,
    removeSurveyErrors: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case OFFLINE_SURVEY_REQUEST:
            return {
                ...state,
                offlineSurveys: [],
            }
        case OFFLINE_SURVEY_SUCCESS:
            return {
                ...state,
                offlineSurveys: action.payload,
            }
        case OFFLINE_SURVEY_FAILURE:
            return {
                ...state,
                offlineSurveys: [],
            }
        case GET_RECENT_SURVEYS_REQUEST:
            return {
                ...state,
                loading: true,
                errors: null,
            }
        case GET_RECENT_SURVEYS_SUCCESS:
            return {
                ...state,
                loading: false,
                recentSurveys: action.payload.recent_surveys,
                recentSurveysCount: action.payload.count,
            }
        case GET_RECENT_SURVEYS_FAILURE:
            return {
                ...state,
                loading: false,
            }
        case GET_SURVEYS_DUE_REQUEST:
            return {
                ...state,
                loadingSurveysDue: true
            }
        case GET_SURVEYS_DUE_SUCCESS:
            return {
                ...state,
                loadingSurveysDue: false,
                surveysDue: action.payload.surveys_due,
                surveysDueCount: action.payload.count,
            }
        case GET_SURVEYS_DUE_FAILURE:
            return {
                ...state,
                loadingSurveysDue: false,
                surveysDue: [],
                surveysDueCount: 0
            }
        case SURVEY_SEARCH_REQUEST:
            return {
                ...state,
                loadingSurveysDue: true,
            }
        case SURVEY_SEARCH_SUCCESS:
            return {
                ...state,
                loadingSurveysDue: false,
                surveysDue: action.payload.results,
                surveysDueCount: action.payload.count,
            }
        case SURVEY_SEARCH_FAILURE:
            return {
                ...state,
                loadingSurveysDue: false,
                surveysDue: [],
                surveysDueCount: 0,
            }
        default:
            return state;
    }
};