import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import Localbase from 'localbase';
import _ from 'lodash';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';

import { addDoor, removeDoor, saveDoorToLocalStorage } from '../../actions/doorActions';
import { getDependencies } from '../../actions/appActions';
import { CLEAR_DOOR_REDIRECT } from '../../types/doorTypes';
import { sortDoors } from '../../functions/utils';
import answers from '../../constants/answers';

import './styles/DoorList.css';

export const DoorList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { search } = useLocation();

    const [errorMessage, setErrorMessage] = useState('');
    const [confirmation, setConfirmation] = useState(false);
    const [doorToRemove, setDoorToRemove] = useState(null);
    const [showError, setShowError] = useState(false);
    const [doorPhotoErrors, setDoorPhotoErrors] = useState([]);

    const surveySelector = useSelector((state) => state.survey);
    const { hotelName, surveyName, surveyId, entryRedirect } = surveySelector;
    
    const doorSelector = useSelector((state) => state.door);
    const { doors: doorsObj, loading, redirectToNextDoor, saveToLocalStorage } = doorSelector;

    const doors = doorsObj ? _.values(doorsObj).filter((d) => !d.deleted) : [];

    const db = new Localbase('napfis-hotelapp');

    doors.sort(sortDoors);

    const errors = doors.map((door) => {
        if (!door.deleted && door.leftId === null) {
            let doorErrors = [];

            doorErrors.push(...door.answers.filter((answer) => answer.answer === answers.NOT_ANSWERED));

            // if (door.photographs.photograph_1 === "") {
            //     doorErrors.push("Missing first photograph");
            // }
            
            if (door.ref === "") {
                doorErrors.push("Missing door ref");
            }

            if (door.type === "") {
                doorErrors.push("Missing door type");
            }

            const rightDoor = _.values(doors).find((d) => d.leftId === door.id);

            if (rightDoor) {
                doorErrors.push(...rightDoor.answers.filter((answer) => answer.answer === answers.NOT_ANSWERED));
            }

            return {
                id: door.id,
                errors: doorErrors
            }
        }

        return null;
    });

    const handleRemoveDoor = (doorId) => {
        setConfirmation(true);
        setDoorToRemove(doorId);
    }

    const handleRemoveDoorConfirm = (doorId) => {
        dispatch(removeDoor(doorId));
        setConfirmation(false);
        setDoorToRemove(null);
    }

    const handleRemoveDoorCancel = () => {
        setConfirmation(false);
        setDoorToRemove(null);
    }

    const handleProceedToSummary = () => {
        if (errors.some((error) => error !== null && error.errors.length > 0 ? true : false) || doorPhotoErrors.length > 0) {
            setErrorMessage('You have an incomplete door inspection. Please rectify and save your doors to continue.');
            setShowError(true);
        } else {
            setShowError(false);
            history.push('/survey/summary');
        } 
    }

    const handleAddDoor = () => {
        dispatch(addDoor(null, null, "", true, 'doors/'));
    }

    useEffect(() => {
        const doorPhotoErrors = async () => {
            doors.map(async (door) => {
                if (!door.deleted && door.leftId === null) {
                    const document = await db.collection('door-photos').doc({ id: door.id, name: 'photograph_1' }).get();
        
                    if (!document) {
                        setDoorPhotoErrors((prev) => [...prev, door.id]);
                    }
                }
            });
        }

        doorPhotoErrors()
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        dispatch(getDependencies());
    }, [dispatch]);

    useEffect(() => {
        if (saveToLocalStorage) {
            dispatch(saveDoorToLocalStorage());
        }
    }, [saveToLocalStorage, dispatch]);

    useEffect(() => {
        if (entryRedirect) {
            history.push(entryRedirect);

            dispatch({ type: CLEAR_DOOR_REDIRECT });
        }
    }, [entryRedirect, dispatch, history]);

    useEffect(() => {
        if (redirectToNextDoor) {
            history.push(redirectToNextDoor);

            dispatch({ type: CLEAR_DOOR_REDIRECT });
        }
    }, [redirectToNextDoor, dispatch, history]);

    return (
        <Fragment>
            <Header>{hotelName}: {surveyName}</Header>
            <Menu />
            <Content style={{ marginTop: '100.27px' }}>
                <div className="doorList">
                    <p style={{ textAlign: 'center' }}>Browse through each door and complete a series of checks to determine if the doors are compliant/meet regulations. Once you've completed each door, click process survey.</p>
                    {!surveyId && (
                        <div className="form__buttons" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                            <button className="form__button secondary addDoor" onClick={handleAddDoor}>Add Door</button>
                            {doors.length > 9 && (
                                <div className="form__buttons" style={{ marginTop: '0rem' }}>
                                    <button className="form__button form__button--inline processSurvey" onClick={handleProceedToSummary} disabled={doors.length < 1}>Process Survey</button>
                                </div>
                            )}
                        </div>
                    )}
                    {(showError || search.includes('error')) && doors.length > 9 && (<div style={{ textAlign: 'center', marginTop: '1rem' }}>{errorMessage}</div>)}
                    {!loading ? doors.length > 0 ? doors.map((door) => {
                        let errorClass = 'field-completed';

                        const hasError = errors.find((error) => error !== null && error.id === door.id && error.errors.length > 0);
                        let isFailed = door.answers.filter((d) => d.answer === answers.NOT_ANSWERED || d.answer === answers.FAILED).length > 0;

                        const rightDoor = _.values(doors).find((d) => d.leftId === door.id);

                        if (rightDoor) {
                            if (!isFailed) {
                                isFailed = rightDoor.answers.filter((d) => d.answer === answers.FAILED).length > 0;
                            }
                        }

                        if (hasError) {
                            errorClass = 'field-incompleted';
                        }
                
                        if (!door.deleted && !door.leftId) {
                            const hasPhotoError = doorPhotoErrors.find((err) => err === door.id);

                            if (hasPhotoError) {
                                errorClass = 'field-incompleted';
                            }

                            return (
                                <div key={door.id} className={`doorList__container ${errorClass} ${showError && 'show'}`}>
                                    <div className="doorList__content">
                                        <span>{door?.ref}</span>
                                        <div className="doorList__validationContainer">
                                            {(hasError || hasPhotoError) && (
                                                <span className='door-error'>
                                                    <FontAwesomeIcon icon={faExclamationCircle} size="lg" color="#CD2121" />
                                                </span>
                                            )}
                                            {isFailed ? (
                                                <span className="door-incomplete">
                                                    <FontAwesomeIcon icon={faTimes} size="lg" color="#CD2121" />
                                                </span>
                                            ) : (
                                                <span className="door-complete">
                                                    <FontAwesomeIcon icon={faCheck} size="lg" color="#4BC33E" />
                                                </span>
                                            )}
                                        </div>    
                                    </div>
                                    <Link className="edit" to={`/survey/doors/${door?.id}`} style={{ marginRight: '1rem' }}>Edit</Link>
                                    {!surveyId && (
                                        <button className="remove" onClick={() => handleRemoveDoor(door.id)}>Remove</button>
                                    )}
                                </div>
                            );
                        }

                        return null;
                    }) : (
                        <Fragment>
                            <div style={{ textAlign: 'center', marginTop: '1rem' }}>No doors set. Start adding doors by clicking the 'Add Door' button.</div>
                        </Fragment>
                    ) : (
                        <div style={{ textAlign: 'center', marginTop: '1rem' }}>Loading...</div>
                    )}
                    {doors.length > 0 && (
                        <div className="form__buttons">
                            <button className="form__button form__button--inline processSurvey" onClick={handleProceedToSummary} disabled={doors.length < 1}>Process Survey</button>
                        </div>
                    )}
                    {(showError || search.includes('error')) && (<div style={{ textAlign: 'center', marginTop: '1rem' }}>{errorMessage}</div>)}
                    {confirmation && (
                        <div class="popup">
                            <p className="title">Are you sure you want to delete this door?</p>
                            <div className="form__buttons">
                                <button onClick={handleRemoveDoorCancel} className="form__button secondary">No</button>
                                <button onClick={() => handleRemoveDoorConfirm(doorToRemove)} className="form__button primary">Yes</button>
                            </div>
                        </div>
                    )}
                </div>
            </Content>
            <BottomNav />
        </Fragment>
    )
}
