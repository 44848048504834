import React, { Fragment, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';
import RepairHistoryEntry from '../../components/RepairHistoryEntry';

import { loadRepairHistory } from '../../actions/repairsActions';

import styles from './RepairHistory.module.css';

export default function RepairHistory() {
    const dispatch = useDispatch();
    const { id } = useParams();
    
    const surveyDashboardSelector = useSelector((state) => state.surveyDashboard);
    const { hotelName, surveyName } = surveyDashboardSelector;
    
    const repairsSelector = useSelector((state) => state.repairs);
    const { history, historyLoading, latestSurvey } = repairsSelector;

    useEffect(() => {
        dispatch(loadRepairHistory(id));
    }, [id, dispatch]);

    return (
        <Fragment>
            <Header>{hotelName}: {surveyName}</Header>
            <Menu />
            <Content style={{ marginTop: '100.27px' }} >
                <div className={styles.headingContainer}>
                    <h2 className={styles.heading}>Repair History</h2>
                    <div className={styles.headingButtonContainer}>
                        <Link to={`/survey/${id}/repairs`} className={styles.secondaryButton} style={{ marginRight: '2rem' }}>Outstanding Repairs</Link>
                        <Link to={`/survey/${id}`} className={styles.finishButton}>Finish</Link>
                    </div>
                </div>
                {latestSurvey && (
                    <div className={styles.lastSurvey}>
                        <span style={{ fontWeight: '600' }}>Last Survey:</span>
                        <span className={styles.lastSurveyDate}>{latestSurvey}</span>
                    </div>
                )}
                <div className={styles.repairsContainer}>
                    {historyLoading ? (
                        <div>Loading...</div>
                    ) : history ? (
                        <>
                            {_.values(history).map((door) => (
                                <>
                                    {door?.repairs && door?.repairs.map((repair) => (
                                            <RepairHistoryEntry repairRef={door?.ref} message={repair?.repair_message} urgent={repair?.urgent} completion={repair?.appointment_completion} notes={repair?.appointment_notes} />
                                        )
                                    )}
                                </>
                            ))}
                        </>
                    ) : (
                        <div>No repairs</div>
                    )}
                </div>
            </Content>
            <BottomNav />
        </Fragment>
    )
}
