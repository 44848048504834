import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import EditRepairForm from '../EditRepairForm';

import styles from './styles/DoorRepairPopup.module.css';

export default function DoorRepairPopup({
    id,
    appointmentId,
    surveyId,
    fieldId,
    doorRef,
    message,
    onNextRepair,
    resetSection
}) {
    const [showForm, setShowForm] = useState(false);

    const handleRepaired = () => {
        setShowForm(true);
    };

    const handleNext = () => {
        onNextRepair();
        setShowForm(false);
    };

    useEffect(() => {
        setShowForm(false);
    }, [id, surveyId, fieldId]);

    return (
        <div className={styles.background}>
            <div className={styles.sectionContainer}>
                <div className={styles.sectionHeading}>
                    <div className={styles.sectionIcon}>
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            color="#cc2020"
                            size="lg"
                        />
                    </div>
                    <h3 className={styles.sectionHeader}>Repair History</h3>
                </div>
                <div className={styles.sectionMessageContainer}>
                    <span className={styles.sectionRef}>{doorRef}</span>
                    <p className={styles.sectionMessage}>{message}</p>
                </div>
                <div className={styles.sectionButtonsContainer}>
                    <button
                        className={styles.repairedButton}
                        onClick={handleRepaired}
                    >
                        Repaired
                    </button>
                    <button
                        className={styles.notRepairedButton}
                        onClick={handleNext}
                    >
                        Not Repaired
                    </button>
                </div>
                {showForm && (
                    <div className={styles.repairContainer}>
                        <EditRepairForm
                            appointmentId={appointmentId}
                            surveyId={surveyId}
                            entryId={id}
                            fieldId={fieldId}
                            onSave={handleNext}
                            resetSection={resetSection}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
