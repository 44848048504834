import React, { useState } from 'react';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cls from 'classnames';

import styles from './styles/RepairHistoryEntry.module.css';

export default function RepairHistoryEntry({ repairRef, message, urgent, completion, notes }) {
    const [showPopup, setShowPopup] = useState(false);

    return (
        <>
            <div className={cls(urgent ? styles.urgent : '', styles.repairContainer)} onClick={() => setShowPopup((prev) => !prev)}>
                <div className={styles.repairDoor}>{repairRef}</div>
                <div className={styles.repairIcon}>{urgent && (<FontAwesomeIcon icon={faExclamationTriangle} color='#CC2127' size='lg' />)}</div>
                <div className={styles.repairMessage}>{message}</div>
            </div>
            {showPopup && (
                <div className={styles.background} onClick={() => setShowPopup((prev) => !prev)}>
                    <div className={styles.container}>
                        <p>{repairRef}</p>
                        <p>{message}</p>
                        <span className={styles.completion}>{completion}</span>
                        <p className={styles.notes}>{notes}</p>
                    </div>
                </div>
            )}
        </>
    )
}