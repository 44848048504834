import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookie from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import doorSections from '../../constants/doorSections';

import styles from './styles/Photo.module.css';

export default function Photo({ id, name, photo, uploaded, error }) {
    const [uploadedPhoto, setUploadedPhoto] = useState(false);
    const [errorPhoto, setErrorPhoto] = useState(false);

    const handlePhotoUpload = async (id, name, photo) => {
        const fd = new FormData();
        const fieldName = name === 'photograph_1' ? doorSections.PHOTOGRAPH_ONE : doorSections.PHOTOGRAPH_TWO;

        fd.append(fieldName, photo);

        try {
            const response = await axios.post('https://app.webglu21.co.uk/app_handler.php', fd, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'multipart/form-data',
                    'WG-Method': 'SAVE_DOOR_PHOTO',
                    'Wg-Doorid': id,
                    'Wg-Key': Cookie.get('accessToken')
                }
            });

            if (response.status === 200) {
                setUploadedPhoto(true);
            } else {
                setErrorPhoto(true);
            }
        } catch (error) {
            setErrorPhoto(true);
        }
    }

    useEffect(() => {
        if (uploaded) {
            setUploadedPhoto(true);
            setErrorPhoto(false);
        } else if (error) {
            setErrorPhoto(true);
            setUploadedPhoto(false);
        }
    }, [uploaded, error]);

    return (
        <div className={styles.photoContainer}>
            <div className={styles.previewContainer}>
                <img className={styles.preview} alt="Preview" src={URL.createObjectURL(photo)} />
                <button className={styles.removePhoto} onClick={() => console.log(id, name)}>X</button>
                {uploadedPhoto && (
                    <span className={styles.uploadedPhoto}>
                        <FontAwesomeIcon icon={faCheck} color="#4BC33E" />
                    </span>
                )}
                {errorPhoto && (
                    <span className={styles.failedPhoto}>
                        <FontAwesomeIcon icon={faTimes} color="#CD2121" />
                    </span>
                )}
            </div>
            <button className={styles.uploadButton} onClick={() => handlePhotoUpload(id, name, photo)}>Upload</button>
        </div>
    )
}