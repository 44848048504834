import React, { useEffect, useState } from 'react';
import cls from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import EditRepairForm from '../EditRepairForm';

import styles from './EditRepair.module.css';

export default function EditRepair({ id, appointmentId, surveyId, fieldId, doorRef, message, urgent, noSave }) {
    const [showForm, setShowForm] = useState(false);
    const [completed, setCompleted] = useState(false);

    const handleEdit = () => {
        setShowForm(prev => !prev);
    }

    const handleSave = () => {
        setCompleted(true);
        setShowForm(false);
    }

    useEffect(() => {
        setShowForm(false);
    }, [fieldId]);

    return (
        <>
            <div className={cls(urgent ? styles.urgentContainer : '', styles.editRepairContainer)}>
                <div className={styles.ref}>{doorRef}</div>
                <div className={styles.message}>{urgent && (<span className={styles.urgent}>URGENT!</span>)} {message}</div>
                <div className={styles.tickContainer}>
                    {completed && (
                        <div className={styles.tick}>
                            <FontAwesomeIcon icon={faCheck} />
                        </div>
                    )}
                </div>
                <div className={styles.edit}>
                    <button className={styles.editRepair} onClick={handleEdit}>Edit</button>
                </div>
            </div>
            {showForm && (
                <div className={styles.editRepairFormContainer}>
                    <EditRepairForm appointmentId={appointmentId} surveyId={surveyId} entryId={id} fieldId={fieldId} onSave={handleSave} noSave={noSave} />
                </div>
            )}
        </>
    );
}