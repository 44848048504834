import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import Cookie from 'js-cookie';
import cls from 'classnames';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PulseLoader from 'react-spinners/PulseLoader';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';

import { createSurvey } from '../../actions/surveyActions';
import { CLEAR_DOORLIST_PROCEED } from '../../types/surveyTypes';

import styles from './NewSurvey.module.css';

export default function NewSurvey() {
    const dispatch = useDispatch();
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [hotel, setHotel] = useState('');
    const [surveyName, setSurveyName] = useState('');
    const [surveyType, setSurveyType] = useState('');
    const [frequency, setFrequency] = useState('');
    
    const [hotelFields, setHotelFields] = useState([]);
    const [typeFields, setTypeFields] = useState([]);
    const [frequencyFields, setFrequencyFields] = useState([]);

    const authSelector = useSelector((state) => state.auth);
    const { user } = authSelector;

    const surveySelector = useSelector((state) => state.survey);
    const { surveyLoading, doorListProceed } = surveySelector;

    const handleNewSurvey = () => {
        if (hotel && surveyName && surveyType && frequency) {
            dispatch(createSurvey(user?.customer_id, hotel, surveyName, surveyType, frequency));
        } else {
            setError('Please fill out all fields');
        }
    }

    useEffect(() => {
        async function getNewSurveyForm() {
            setLoading(true);

            try {
                const { data } = await axios.get('https://app.webglu21.co.uk/app_handler.php', { headers: { 'WG-Method': 'GET_NEW_SURVEY_FORM', 'WG-Key': Cookie.get('accessToken') }});
    
                if (data) {
                    console.log(data);
                    setHotelFields(data?.hotel_fields);
                    setTypeFields(data?.type_fields);
                    setFrequencyFields(data?.frequency_fields);
                } else {
                    // Load old form
                }
    
            } catch (error) {
                console.error(error);
            }

            setLoading(false);
        }

        getNewSurveyForm();
    }, []);

    useEffect(() => {
        if (doorListProceed) {
            history.push('/survey/doors');

            dispatch({ type: CLEAR_DOORLIST_PROCEED });
        }
    }, [doorListProceed, dispatch, history]);

    return (
        <Fragment>
            <Header>Door Survey: Create</Header>
            <Menu />
            <Content style={{ marginTop: '100.27px' }} >
                {loading ? (
                    <div>Loading</div>
                ) : (
                    <>
                        <div>
                            <div className="form-group">
                                <label className="form__label" htmlFor="hotelName">Hotel Name</label>
                                <select className="form__select" id="hotelName" value={hotel} onChange={(e) => setHotel(e.target.value)}>
                                    <option>-- Please select a hotel --</option>
                                    {hotelFields && hotelFields.map((field) => (
                                        <option key={field?.field_option_id} value={field?.field_option_id}>{field?.option_name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label className="form__label" htmlFor="surveyName">Survey Name</label>
                                <input className="form__input" id="surveyName" value={surveyName} onChange={(e) => setSurveyName(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label className="form__label" htmlFor="surveyType">Survey Type</label>
                                <select className="form__select" id="surveyType" value={surveyType} onChange={(e) => setSurveyType(e.target.value)}>
                                    <option>-- Please select a type --</option>
                                    {typeFields && typeFields.map((field) => (
                                        <option key={field?.field_option_id} value={field?.field_option_id}>{field?.option_name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label className="form__label" htmlFor="frequency">Frequency</label>
                                <select className="form__select" id="frequency" value={frequency} onChange={(e) => setFrequency(e.target.value)}>
                                    <option>-- Please select a frequency --</option>
                                    {frequencyFields && frequencyFields.map((field) => (
                                        <option key={field?.field_option_id} value={field?.field_option_id}>{field?.option_name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        {error && (
                            <div className={cls(styles.errorContainer, 'error')}>{error}</div>
                        )}
                        <div className="form__buttons">
                            <button className="form__button" aria-label="Save & Continue" onClick={handleNewSurvey}>Save & Continue <PulseLoader loading={surveyLoading} color={'#ffffff'} css={'margin-left: 8px'} size={5} /></button>
                        </div>
                    </>
                )}
            </Content>
            <BottomNav />
        </Fragment>
    )
}
