export const LOAD_SURVEY_REQUEST = 'LOAD_SURVEY_REQUEST';
export const LOAD_SURVEY_SUCCESS = 'LOAD_SURVEY_SUCCESS';
export const LOAD_SURVEY_FAILURE = 'LOAD_SURVEY_FAILURE';

export const LOAD_RECENT_SURVEYS_REQUEST = 'LOAD_RECENT_SURVEYS_REQUEST';
export const LOAD_RECENT_SURVEYS_SUCCESS = 'LOAD_RECENT_SURVEYS_SUCCESS';
export const LOAD_RECENT_SURVEYS_FAILURE = 'LOAD_RECENT_SURVEYS_FAILURE';

export const LOAD_SURVEYS_DUE_REQUEST = 'LOAD_SURVEYS_DUE_REQUEST';
export const LOAD_SURVEYS_DUE_SUCCESS = 'LOAD_SURVEYS_DUE_SUCCESS';
export const LOAD_SURVEYS_DUE_FAILURE = 'LOAD_SURVEYS_DUE_FAILURE';

export const REMOVE_SURVEY_ENTRY_REQUEST = 'REMOVE_SURVEY_ENTRY_REQUEST';
export const REMOVE_SURVEY_ENTRY_SUCCESS = 'REMOVE_SURVEY_ENTRY_SUCCESS';
export const REMOVE_SURVEY_ENTRY_FAILURE = 'REMOVE_SURVEY_ENTRY_FAILURE';

export const REMOVE_SURVEY_REQUEST = 'REMOVE_SURVEY_REQUEST';
export const REMOVE_SURVEY_SUCCESS = 'REMOVE_SURVEY_SUCCESS';
export const REMOVE_SURVEY_FAILURE = 'REMOVE_SURVEY_FAILURE';