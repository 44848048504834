import React, { Fragment, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';
import EditRepair from '../../components/EditRepair';

import { loadRepairs } from '../../actions/repairsActions';

import styles from './SurveyRepairs.module.css';

export default function SurveyRepairs() {
    const dispatch = useDispatch();
    const { id } = useParams();
    
    const surveyDashboardSelector = useSelector((state) => state.surveyDashboard);
    const { hotelName, surveyName } = surveyDashboardSelector;

    const repairsSelector = useSelector((state) => state.repairs);
    const { repairs, loading, latestSurvey } = repairsSelector;

    useEffect(() => {
        dispatch(loadRepairs(id));
    }, [id, dispatch]);

    return (
        <Fragment>
            <Header>{hotelName}: {surveyName}</Header>
            <Menu />
            <Content style={{ marginTop: '100.27px' }} >
                <div className={styles.headingContainer}>
                    <h2 className={styles.heading}>Repair History: Outstanding Repairs</h2>
                    <div className={styles.headingButtonContainer}>
                        <Link className={styles.secondaryButton} to={`/survey/${id}/repair-history`} style={{ marginRight: '2rem' }}>Completed Repairs</Link>
                        <Link className={styles.finishButton} to={`/survey/${id}`}>Finish</Link>
                    </div>
                </div>
                {latestSurvey && (
                    <div className={styles.lastSurvey}>
                        <span style={{ fontWeight: '600' }}>Last Survey:</span>
                        <span className={styles.lastSurveyDate}>{latestSurvey}</span>
                    </div>
                )}
                <div className={styles.repairsContainer}>
                    {loading ? (
                        <div>Loading...</div>
                    ) : (
                        <>
                            {repairs && _.values(repairs).map((door) => (
                                <>
                                    {door?.repairs && door?.repairs.map((repair) => (
                                            <EditRepair key={repair?.appointment_id} id={repair?.primary_id} appointmentId={repair?.appointment_id} surveyId={id} fieldId={repair?.field_id} doorRef={door?.ref} message={repair?.repair_message} urgent={repair?.urgent} />
                                        )
                                    )}
                                </>
                            ))}
                        </>
                    )}
                </div>
            </Content>
            <BottomNav />
        </Fragment>
    )
}
